@import "../../styles/index";

.notification-manager {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  transition: all 0.3s ease;
  overflow-x: hidden;
}

.detecting-extension {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $brand-color;
  background-image: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.3) 50%,
    transparent 100%
  );
  background-repeat: no-repeat;
  background-position: -100vw;
  animation: shine 2s infinite;
  font-size: 14px;
  color: $secondary-background-color;
  height: 48px;
  width: 100%;
  z-index: 2;

  .spinner {
    margin-top: 4px;
    margin-right: 8px;
    top: 2px;
    right: 3px;
  }
}

.m-b-4 {
  margin-bottom: 4px;
}

@keyframes shine {
  0% {
    background-position: -100vw;
  }
  100% {
    background-position: 100vw;
  }
}
