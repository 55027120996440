.welcome {
  &__container {
    font-size: 16px;
  }

  &__contact {
    display: flex;
    align-items: center;
  }

  &__image {
    width: 50px;
    height: 50px;
    margin-right: 16px;
    border-radius: 50%;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    padding: 20px 100px 0 100px;
    font-weight: 500;

    @media screen and (max-width: 767px) {
      padding: 20px 20px 0;
    }

    a {
      color: rgb(19, 84, 78);
      font-weight: 600;
    }
  }

  &__contact-info {
    & > p {
      margin: 0;
      font-weight: 600;
    }
  }

  &__video {
    min-height: 330px;
    border-radius: 0;
    border: none;
    margin-bottom: 12px;
    margin-top: 18px;
    width: -webkit-fill-available;
  }
}
