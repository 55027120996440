@import "./src/styles";

.plans {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;

  .row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 24px;
    width: 100%;
    gap: 24px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    margin: 0 0 8px;
  }

  .section-description {
    display: flex;
    align-items: center;
    color: $primary-text-color;
    padding: 0;
    margin: 0 0 24px;
  }

  .back-button {
    margin-top: 16px;
  }

  @media (max-width: 680px) {
    .plan {
      width: 51%;
    }
  }

  @media (max-width: 767px) {
    .row {
      width: 100%;

      .plan {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
