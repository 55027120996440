@import './src/styles';

.tabs {
  display: flex;
  flex-direction: column;
  background: $secondary-background-color;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: $primary-text-color;
  width: 100%;
  
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid $tabs-button-background-color;
    padding: 0;
    margin: 0;
    height: 40px;
    width: 100%;
  }
  
  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-text-color;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    list-style-type: none;
    cursor: pointer;
    padding: 15px 8px;
    min-height: 20px;
    min-width: 124px;
    transition: all .1s ease;
    
    &--active,
    &:hover {
      &:after {
        content: '';
        position: absolute;
        bottom: 4px;
        right: 0;
        height: 2px;
        background: $brand-color;
        width: 100%;
      }
      border-color: $brand-color;
    }
  }
  
  &__content {
    display: flex;
    flex-direction: column;
    padding: 32px 0;
  }
}
