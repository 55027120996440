@import './src/styles';

.cancel-popup {
  &__body {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $thirdly-text-color;
    border-bottom: 1px solid $thirdly-text-color;
    padding: 16px 0;
    color: $primary-text-color;
    margin: 0;
    width: 100%;
  }
  
  &__main-text {
    font-size: 14px;
  }
  
  &__secondary-text {
    font-size: 10px;
    margin-top: 8px;
  }
  
  .smiley-face {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    cursor: pointer;
    margin-top: 2px;
    margin-left: 4px;
  }
  
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}