@import './src/styles';

.column {
  display: flex;
  flex-direction: column;
}

.settings-form {
  @extend .column;

  .setting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
    width: 100%;
    
    &__label {
      display: flex;
      
      svg  {
        position: relative;
        top: 2px;
        margin-left: 4px;
        fill: $brand-color;
      }
    }
  }
  
  .no-crm-settings {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    text-align: center;
    line-height: 21px;
    background: $no-crm-settings-block-background-color;
    width: 100%;
    flex-wrap: wrap;
    
    b, .link {
      margin: 0 3px;
      color: $brand-color;
    }
    
    a {
      color: $brand-color;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .expandable-setting {
    @extend .column;

    .create-deal-section {
      @extend .column;
      margin-top: 16px;

      .ant-tag {
        cursor: pointer;
        margin: 0;
      }

      .section-title {
        font-size: 14px;
        font-weight: bold;
        line-height: 21px;
        margin: 0 0 8px
      }

      .note {
        font-size: 12px;
        font-style: italic;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }
    
    &__inner-switch {
      margin-top: 14px;
    }
  }

  .form-button {
    align-self: flex-end;
    height: 32px;
    width: 120px;
    margin-top: 16px;
  }
  
  .settings__title {
    font-size: 21px;
    line-height: 27px;
    font-weight: bold;
    border-bottom: 1px solid rgb(243, 243, 243);
    padding-bottom: 14px;
    margin-bottom: 24px;
  }
  
  @media (max-width: 480px) {
    .setting__label {
      margin-right: 10px;
    }
  }
}