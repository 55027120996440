@import './src/styles';

.payment-processing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $secondary-background-color;
  color: $primary-text-color;
  height: 100%;
  width: 100%;
  
  .text-base {
    margin: 0;
    padding: 0;
    letter-spacing: .5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &__main-title {
    @extend .text-base;
    font-size: 42px;
    line-height: 54px;
    font-weight: bold;
    color: $brand-color;
    text-align: center;
  }
  
  &__sub-title {
    @extend .text-base;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 12px;
    font-weight: bold;
    text-align: center;
  }
  
  &__progress-bar {
    margin: 32px 0 24px;
  }
  
  &__paragraph {
    @extend .text-base;
    font-size: 14px;
    line-height: 21px;
  }
  
  &.hidden {
    display: none;
  }
}