@import './src/styles';

.basic-column {
  display: flex;
  flex-direction: column;
}

.registration {
 @extend .basic-column;
  justify-content: center;
  background: $secondary-background-color;
  padding: 32px;
  height: 100%;
  
  @media (max-height: 414px) and (max-width: 915px) and (orientation: landscape) {
    height: auto;
  }
  
  &__submit-button {
    margin-top: 16px;
    width: 100%;
    
    &.button.ant-btn {
      height: 40px;
    }
  }
  
  .brand-logo {
    margin-bottom: 48px;
    width: 100%;
  }
  
  &__content {
    @extend .basic-column;
    align-self: center;
    justify-content: center;
    height: 100%;
    max-width: 380px;
    width: 100%;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 4px;
  }

  &__description {
    font-size: 14px;
    line-height: 21px;
    color: $primary-text-color;
    margin: 0 0 16px;
  }

  &__google-button {
    font-weight: bold;
  }

  input {
    height: 40px;
  }

  &__terms-and-conditions {
    font-size: 14px;
    line-height: 21px;
    color: $primary-text-color;

    a {
      color: $brand-color;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__delimiter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 32px 0;

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      height: 1px;
      background: $primary-text-color;
    }

    span {
      font-size: 12px;
      color: $primary-text-color;
      background: $secondary-background-color;
      padding: 4px 16px;
      z-index: 10;
    }
  }
  
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 21px;
    font-weight: bold;
    text-align: center;
    color: $primary-text-color;
  
    a {
      color: $brand-color;
      font-weight: bold;
    }
  }
  
  .google-provider {
    display: flex;
    align-items: center;
    width: 100%;
    
    * {
      width: 100%;
      
      iframe {
        width: 100%;
      }
    }
    
    &--disabled {
      pointer-events: none;
    }
  }
}