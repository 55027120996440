@import "./src/styles";

.job-searcher-stats {
  &__content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 24px;

    .plan-card {
      width: 25%;
    }
  }

  &__default {
    color: #84e8e2 !important;
  }

  &__success {
    color: #c2ec8b !important;
  }

  &__failed {
    color: #ffa39e !important;
  }

  &__middle {
    color: #fcb766 !important;
  }
}
