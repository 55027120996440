@import './src/styles';

.svg-icon {
  display: flex;
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-left: 6px;
  
  svg {
    fill: $brand-color;
    height: 100%;
  }
}