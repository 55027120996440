@import '../../../styles/index';

.release-notification {
  $base: &;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  padding: 16px;
  width: 100%;
  
  .close-button {
    border: none;
    background-color: rgba(225, 87, 79, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    color: #fff;
    border-bottom-left-radius: 10px;
    position: absolute;
    right: -3px;
    top: -2px;
    padding: 8px;
    cursor: pointer;
    
    &:hover {
      opacity: .8;
    }
  }
  
  &.success {
    background-image: $notification-success-linear-gradient;
  }
  
  &.error {
    background: $notification-error-linear-gradient;
  }
  
  &.technical {
    min-height: 60px;
    background-image: $notification-technical-linear-gradient;
  }
  
  &__paragraph {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
    color: $primary-text-color;
    width: 100%;
    
    .row {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
  
      .title,
      .description {
        margin: 0;
        padding: 0;
        line-height: 18px;
        width: 100%;
      }
      
      .title {
        font-size: 14px;
        font-weight: bold;
      }
      
      .description {
        font-size: 12px;
      }
    }
  
    .button.ant-btn {
      margin-left: 24px;
      min-width: 120px;
    }
    
    img {
      height: 12px;
      width: 12px;
      margin-right: 4px;
    }
    
    @media (max-width: 480px) {
      display: block;
      line-height: 21px;
    }
  }
  
  @media (max-width: 767px) {
    #{$base}__paragraph {
      .row {
        flex-direction: column;
        
        button {
          margin-left: 0;
          margin-top: 16px;
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    height: auto;
  }
}