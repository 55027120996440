@import './src/styles';

.multi-select {
  display: flex;
  align-items: center;
  min-width: 230px;
  
  .ant-select-selector {
    width: 100%;
  }
}