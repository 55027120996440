.update-billing-panel {
  margin-top: 24px;

  .update-billing {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__note {
      font-size: 14px;
      line-height: 18px;
      margin: 0;
    }

    &__form {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
    }

    &__fields {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      .field-input {
        margin-bottom: 24px;
        width: 45%;
      }
    }

    &__buttons-panel {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        height: 32px;
        margin-left: 16px;
      }
    }
  }
}