@import "./src/styles";

.toobar-notification {
  display: flex;
  gap: 10px;
}

.top-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-height: 84px;
  padding: 8px 16px;
  width: 100%;

  .panel__content {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .container {
    display: flex;
    align-self: center;
    justify-content: flex-end;
    width: 300px;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .online-indicator {
      display: flex;
      height: 12px;
      width: 12px;
      background: $online-indicator-color;
      border: 1px solid $primary-background-color;
      border-radius: 50%;
      position: absolute;
      bottom: 2px;
      right: -1px;
    }

    .user-details {
      display: flex;
      flex-direction: column;
      margin-right: 16px;

      .details {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        line-height: 18px;
        color: $primary-text-color;

        &:nth-child(1) {
          font-weight: bold;
        }
      }
    }
  }

  .spinner {
    font-size: 12px;
    position: relative;
    margin-top: 8px;
    margin-right: 12px;
    height: 20px;

    .ant-spin {
      height: 20px;
    }
  }

  .extension-status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    line-height: 18px;
    margin: 8px 0 0;
    cursor: initial !important;

    .tag-link {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    svg {
      height: 12px;
      width: 12px;
      margin-left: 4px;

      &.success {
        fill: $brand-color;
      }

      &.failed {
        fill: $error-color;
      }

      &.updated {
        fill: $extension-updated-color;
      }
    }

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
