@import './src/styles';

.requested-feature-column {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  background: #f7f8fa;
  border: 1px solid #e4e7eb;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .05) 0 3px 12px;
  overflow: hidden;
  position: relative;
  height: 100%;
  min-width: 275px;
  margin-right: 16px;
  padding: 16px 8px;
  width: 25%;
  
  &:last-child {
    margin-right: 0;
  }
  
  &__heading {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 8px;
    margin-bottom: 16px;
  }
  
  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
  }
  
  &__status {
    border-radius: 100%;
    height: 8px;
    margin-right: 8px;
    min-width: 8px;
    width: 8px;
  }
  
  &__title {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
    color: #787f8c;
  }
}