@import "./src/styles";

.ai-job-researcher {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 21px;
  color: $primary-text-color;

  &__paragraph {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
  }

  &__available {
    color: #389e0d;
    background: #f6ffed;
    border: 1px solid #b7eb8f;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 2px 8px;
    font-size: 14px;
    height: 28px;
  }

  b {
    font-weight: bold;
    color: $brand-color;
    cursor: pointer;
  }

  i {
    font-weight: 600;
    font-style: normal;
    padding-left: 4px;
  }
}
