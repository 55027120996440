@import "./src/styles";

.logs {
  .data-table {
    tbody {
      tr {
        cursor: inherit;
      }

      td {
        padding: 6px 12px;
      }

      .value {
        min-width: 120px;
      }
    }
  }
}
