@import "./src/styles";

.webhook-url {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;

    .ant-divider {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__row {
    display: flex;
    gap: 24px;
  }

  &__row-item {
    width: calc(50% - 12px);
    display: flex;
    flex-direction: column;
    gap: 6px;

    &.fully {
      width: 100%;
    }

    button {
      margin: 0;
      width: fit-content;
    }

    &.pt-space {
      padding-top: 28px;
    }

    &.pt-small-space {
      padding-top: 16px;
    }

    .ant-select-selector {
      height: 40px !important;

      .ant-select-selection-item {
        line-height: 40px !important;
      }
    }
  }

  &__section-title {
    font-weight: 600;
  }

  &__button {
    display: flex;
    margin: 16px 0 10px auto;
    min-width: 120px;
  }

  &__info {
    border: 1px solid rgb(243, 243, 243);
    border-radius: 4px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  &__info-item {
    display: flex;
    align-items: center;
    gap: 10px;

    .ant-tag {
      text-transform: capitalize;
    }

    & > span {
      font-weight: 600;
    }
  }
}
