@import "./src/styles";

.offer {
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 36px 28px;
    background: $secondary-background-color;
    box-shadow: $box-shadow;
    border-radius: 8px;
    margin-bottom: 32px;
    position: relative;
    width: 49%;
    max-width: 49%;
    z-index: 2;

    @media screen and (max-width: 1023px) {
      width: 100%;
      max-width: 100%;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;

    & > svg {
      font-size: 40px;
      color: rgb(19, 84, 78);
      display: none;
      margin-right: 20px;
    }
  }

  &__content-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__title {
    margin: 0;
    font-size: 28px;
    line-height: 40px;
    text-transform: uppercase;
    color: #001246;
  }

  &__subtitle {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #001246;
  }

  &__details {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    width: 100%;
  }

  &__desc {
    margin: 0;
    font-size: 18px;
    color: #001246;
  }

  &__details-content {
    display: flex;
    flex-direction: column;
  }

  &__details-item {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    p {
      margin: 0;
      font-size: 16px;
      color: #001246;
      font-weight: 500;
    }

    span {
      color: #8b94b2;
      font-size: 14px;
    }

    a {
      text-decoration: none;
      font-weight: 700;
      color: rgb(19, 84, 78);
    }
  }
}
