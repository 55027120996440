.invite-team-member {
  display: flex;
  flex-direction: column;

  .invite-member-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    .field-input {
      width: 100%;
    }

    button {
      height: 32px;
      margin-top: 10px;
    }
  }
}