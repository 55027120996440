@import './src/styles';

.company {
  
  &__heading-title,
  &__title {
    line-height: 21px;
    font-weight: bold;
  }
  
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background: $rounded-table-th-background-color;
    color: $table-th-color;
    padding: 16px;
    width: 100%;
  }
  
  &__title {
    font-size: 14px;
    margin: 24px 0;
  }
  
  &__heading-title {
    font-size: 12px;
    text-transform: uppercase;
    width: 50%;
  }
  
  &__label {
    width: 50%;
  }
  
  &__content,
  &__form {
    display: flex;
    flex-direction: column;
  }
  
  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
  }
  
  &__field-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    
    .field-select {
      width: 100%;
    }
    
    svg {
      margin-right: 16px;
    }
  }
  
  &__button {
    align-self: flex-end;
    margin-top: 32px;
    width: 120px;
  }
}