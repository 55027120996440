@import './src/styles';

.switch {
  &.ant-switch {
    > .ant-switch-inner {
      background: $switch-toggle-background-color;
    }
    
    &.ant-switch-checked {
      > .ant-switch-inner {
        background: $brand-color;
      }
    }
  }
}