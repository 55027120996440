@import "./src/styles";

.tool-card {
  width: 360px;
  border: 1px solid #dedbdb;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  justify-content: space-between;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

  &__actions {
    border-radius: 0;
    display: flex;
    align-items: center;

    button {
      height: 28px !important;
    }
  }

  &__body {
    height: calc(100% - 48px);
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__image {
    width: 40px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 6px;

    & > svg {
      color: rgb(19, 84, 78);
      position: relative;
      height: 14px;
      width: 14px;
      fill: $brand-color;
      cursor: pointer;
    }
  }

  &__desc {
    font-size: 14px;
    line-height: 18px;
    color: #898989;
    font-weight: 500;

    a {
      color: $brand-color;
      font-weight: 600;
      text-decoration: none !important;
    }
  }

  &__soon {
    position: absolute;
    right: -25px;
    top: 12px;
    background: rgb(19, 84, 78);
    color: #fff;
    height: 20px;
    font-size: 12px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg);
    text-transform: uppercase;
  }

  .spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1;
  }
}
