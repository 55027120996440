@import './src/styles';

.reorderable-requested-feature {
  display: flex;
  overflow: auto;
  
  &__draggable-feature {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
  
    .button.ant-btn {
      &.green {
        margin-right: 8px;
      }
      
      &.red {
        margin-left: 8px;
      }
    }
    
    svg {
      display: flex;
      align-self: center;
      color: $pale-error-color;
      margin: 4px 0;
      cursor: pointer;
    }
  }
  
  &__confirmation-buttons {
    display: flex;
    align-self: center;
    justify-content: space-between;
    
    svg {
      color: $primary-background-color;
      margin-right: 4px;
    }
  }
}