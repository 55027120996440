@import './src/styles';

.proposals {
  margin-top: 24px;
  
  .job-title {
    color: #13544E;
    cursor: pointer;
    line-height: 1.6;
    width: 100%;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .panel__heading {
    margin-bottom: 16px;
  }
  
  .back-button {
    margin-bottom: 16px;
  }
  
  .user-name {
    font-size: 14px;
    line-height: 21px;
    color: $primary-text-color;
    margin-bottom: 16px;
  }
  
  button.ant-btn.ant-btn-primary {
    display: inline;
  }
}