@import "./src/styles";

.keys-table {
  .data-table {
    button {
      min-width: auto;
    }

    tbody {
      tr {
        cursor: inherit;
      }

      .value {
        min-width: 120px;
      }
    }
  }
}
