@import "./src/styles";

.basic-column {
  display: flex;
  flex-direction: column;
}

.registration__delimiter {
  margin: 12px 0 !important;
}

.login {
  $base: &;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $brand-color;
  height: 100vh;
  width: 100%;

  .brand-logo {
    margin-bottom: 48px;
    width: 100%;
  }

  &__link {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 21px;
    font-weight: bold;
    color: $primary-text-color;
    margin-top: 16px;

    a {
      margin-left: 4px;
      color: $brand-color;
      font-weight: bold;
    }
  }

  &__column {
    @extend .basic-column;
    height: 100%;
    padding: 32px;

    @media (max-height: 414px) and (max-width: 852px) and (orientation: landscape) {
      height: auto;
    }

    &--left {
      align-items: center;
      justify-content: center;
      background: $secondary-background-color;
      width: 50%;

      #{$base}__content {
        @extend .basic-column;
        align-items: center;
        justify-content: center;
        align-self: center;
        height: 100%;
        max-width: 340px;

        #{$base}__title {
          font-size: 18px;
          line-height: 21px;
          font-weight: bold;
          margin: 0 0 16px;
          width: 100%;
        }

        #{$base}__description {
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.5px;
          color: $primary-text-color;
          margin: 0 0 24px;
        }

        #{$base}__form {
          @extend .basic-column;
          width: 100%;

          #{$base}__button {
            height: 40px;
          }
        }

        #{$base}__button {
          margin-top: 16px;
        }
      }
    }

    &--right {
      align-items: center;
      justify-content: center;
      background: $brand-color;
      width: 50%;
    }
  }

  @media (max-width: 881px) {
    flex-direction: column;

    &__column {
      width: 100%;
    }
  }

  @media (max-height: 414px) and (orientation: landscape) {
    height: auto;
  }

  @media (max-width: 481px) {
    &__column {
      &--right {
        background: $secondary-background-color;
      }
    }
  }
}
