@import './colors';

@mixin table-button {
  height: 32px;
  padding: 8px;
  background: $primary-background-color;
  border: 1px solid $brand-color;
  color: $brand-color;
  font-weight: $font-weight-bold;
  border-radius: 16px;
  margin-left: 8px;
  cursor: pointer;
  transition: .1s all ease;
  
  &:disabled {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, .25);
    background: rgba(0, 0, 0, .04);
    box-shadow: none;
  }
  
  &:hover {
    opacity: .8;
  }
}