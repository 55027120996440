@import './src/styles';

.billing-licences {
  .name {
    margin-bottom: 0;
  }
  
  .field-select {
    width: 160px;
  
    .form-select {
      min-width: unset;
    }
  }
  
  .button-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    button.button.ant-btn {
      color: $secondary-text-color;
      border: none;
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      padding: 3px 20px;
      outline: none;
      text-align: center;
      box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
    }
  }
  
  @media (max-width: 767px) {
    .field-select {
      display: inline-block;
    }
  }
}