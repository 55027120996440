@import "./src/styles";

.ant-drawer-content-wrapper {
  max-width: 1000px;
}

.job-info {
  &__container {
    display: flex;

    p {
      margin: 0;
    }
  }

  &__wrapper {
    margin-bottom: 7px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;

    &.active {
      background: #f9f9f9;
      padding: 10px;
      margin-right: 24px;
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      font-weight: 500;
    }
  }

  &__label {
    display: inline-block;
    margin-inline-end: 8px !important;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }

  &__desc {
    padding-right: 32px;
  }

  &__skills {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }

  &__skill-item {
    border: 1px solid #e4e7eb;
    background-color: #f7f8fa;
    display: flex;
    border-radius: 20px;
    padding: 4px 12px;
  }

  &__title {
    display: block;
    margin-bottom: 16px !important;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5715;

    &.big {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }

  &__stat {
    display: flex;
    flex-direction: column;

    p {
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 500;
    }

    span {
      font-size: 12px;
      color: #7a7a7a;
    }

    svg {
      width: 20px;
    }
  }

  &__left {
    width: 70%;
  }

  &__right {
    width: 30%;
    border-left: 1px solid #f0f0f1;
  }

  &__content {
    padding: 0 16px;

    &.actions {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 18px;

      svg {
        margin-left: 8px;
      }
    }
  }
}
