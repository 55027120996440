.payment-history {
  margin-top: 24px;
  
  .name {
    margin-bottom: 0;
  }
  
  .ant-table-thead > tr > th.ant-table-cell {
    border-radius: 0 !important;
    text-align: center;
  }
  
  .ant-table-tbody tr td {
    text-align: center;
  }
}