@import './src/styles';

.plan-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: $box-shadow;
  
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    padding: 0 16px;
    line-height: 21px;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid #f3f3f3;
    color: $primary-text-color;
    
    svg {
      font-size: 16px;
      line-height: 36px;
      color: $brand-color;
    }
  }
  
  &__body {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 16px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  &__value {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 32px;
    min-height: 44px;
    font-weight: 600;
    color: $brand-color;
  }
  
  button {
    margin-top: 16px;
  }
}