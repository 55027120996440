@import "./src/styles";

.upwex-api {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.upwex-api-modal {
  &__text {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  &__actions {
    width: 100%;

    & > button {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  &__close {
    margin: 18px auto 0 auto;
  }
}
