@import "./src/styles";

.date-range-picker {
  display: flex;
  align-items: center;
  min-width: 230px;
  border-radius: 4px;
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  text-align: center;
}

.ant-picker .ant-picker-input > input {
  cursor: pointer;
}
