@import './src/styles';

.brand-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  
  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    width: 135px;
  
  
    img {
      max-width: 100%;
    }
  }
}