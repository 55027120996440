@import "./src/styles";

.layout {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease;
  overflow-y: auto;
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 25px;
    height: 100%;
    width: 100%;
    background: $primary-background-color;

    @media (min-width: 767px) {
      width: calc(100% - 240px);
    }
  }

  .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
  }

  @media (max-width: 767px) {
    height: unset;
  }
}

.footer-content {
  background: transparent !important;
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
  width: 100% !important;
}
