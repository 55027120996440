@import './src/styles';

.roadmap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  
  &__main-title {
    align-items: center;
    display: flex;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 16px;
    width: 100%;
  }
  
  &__statuses {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
    width: 100%;
  }
  
  &__title {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
    color: #787f8c;
  }
  
  &__link {
    font-size: 14px;
    line-height: 21px;
    font-weight: normal;
    position: relative;
    margin-left: 4px;
    top: 2px;
    color: $brand-color;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
    
    svg {
      height: 12px;
      width: 12px;
      color: $brand-color;
    }
  }
}