@import "./src/styles";

.profile-statistics {
  .avatar-wrap {
    display: flex;
    align-items: center;
  }

  .user-name {
    margin-left: 8px;
    text-align: left;
    flex: 1 1 0;
  }

  .clickable-value {
    display: inline-block;
    color: $brand-color;
    cursor: pointer;
    font-weight: 700;
    display: flex;
    align-items: center;

    & > svg {
      margin-left: 5px;
    }
  }

  @media (max-width: 767px) {
    .avatar-wrap {
      display: inline;
      overflow: hidden;
    }
  }
}
