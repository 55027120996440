@import "./src/styles";

.back-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  color: $brand-color;
  text-decoration: none;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }
}
