@import './src/styles';

.settings {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
  
  .spinner {
    margin: 24px 0;
  }
  
  .setting {
    svg {
      position: relative;
      min-height: 14px;
      min-width: 14px;
      top: 2px;
      margin-left: 4px;
      fill: $brand-color;
    }
  }
  
  .ai-model-duration-text {
    color: $settings_caption_color;
    font-size: 12px;
    margin: 0;
    max-width: 90%;
    min-width: 300px;
  }
}