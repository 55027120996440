@import "./src/styles";

.team-panel {
  margin-top: 24px;
}

.delete-stats-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d9dede;
  background: #dee4e4;
  width: 100%;
  margin-bottom: 8px;

  & > img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    min-width: 24px;
    height: 24px;
  }

  & > span {
    line-height: 18px;
  }
}

.team {
  display: flex;
  flex-direction: column;
  width: 100%;

  .data-table tbody tr td:first-child .value {
    justify-content: flex-start !important;
  }

  .available-licences {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
    flex-wrap: wrap;

    strong {
      margin: 0 2px;
    }

    a {
      color: $brand-color;
    }
  }

  .avatar-wrap {
    display: flex;
    align-items: center;

    .avatar {
      height: 36px;
      width: 36px;
    }
  }

  .available-requests {
    display: inline-flex;
  }

  .popover-icon {
    position: relative;
    height: 14px;
    width: 14px;
    margin-left: 4px;
    fill: rgb(19, 84, 78);
    cursor: pointer;
  }

  .user-name {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    color: $primary-text-color;
    margin-left: 12px;
  }

  .invite-button {
    margin-bottom: 24px;
    width: fit-content;
    padding: 20px;
  }

  .invite-link {
    color: $brand-color;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .ant-table {
    margin-bottom: 32px;
  }

  .spinner {
    margin: 24px 0;
  }

  .ant-table-thead {
    > tr {
      > th.ant-table-cell {
        background: $rounded-table-th-background-color;
      }
    }
  }

  @media (max-width: 767px) {
    .avatar-wrap {
      display: inline-block;
    }
  }

  @media (max-width: 480px) {
    .available-requests .ant-progress-line {
      display: none;
    }
  }

  @media (max-width: 380px) {
    .available-licences {
      font-size: 13px;
    }

    .available-requests__value {
      margin-left: 0;
    }
  }
}

.available-requests {
  display: flex;
  align-items: center;

  .ant-progress-line {
    margin: 0;
    width: 90px;
  }

  &__value {
    margin: 0 6px;
    min-width: 50px;

    &--spent {
      color: $team-available-requests-background-color;
    }

    &--total {
      color: $settings_caption_color;
    }
  }

  &__button.button.ant-btn {
    height: 16px;
    min-width: 16px;
    width: 16px;
    padding: 0;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0 2px;
      height: 100%;
      width: 100%;
    }
  }
}

.assign-license-button.ant-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-width: 60px;
  max-width: 75px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: rgba(19, 84, 78, 0.8) !important;
  }
}
