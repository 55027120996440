// general
$brand-color: rgba(19, 84, 78, 1);
$primary-background-color: rgba(243, 243, 243, 1);
$secondary-background-color: rgba(255, 255, 255, 1);
$primary-text-color: rgba(32, 36, 43, 1);
$secondary-text-color: rgba(255, 255, 255, 1);
$thirdly-text-color: rgba(243, 243, 243, 1);
$dark-charcoal-color: #333;

// components
$home-btn-background-color: rgba(34, 139, 34, 0.75);
$home-btn-hover-background-color: rgba(34, 139, 34, 0.5);

$btn-background-color: $brand-color;
$btn-hover-background-color: rgba(19, 84, 78, 0.8);
$btn-disabled-background-color: rgba(19, 84, 78, 0.1);

$profile-stats-filter-background: #f0f0f0;

$links-from-sidebar-color: rgba(32, 36, 43, 1);
$links-from-sidebar-active-color: rgba(30, 188, 123, 0.1);

$regular-table-th-background-color: rgba(248, 248, 248, 1);
$rounded-table-th-background-color: rgba(19, 84, 78, 0.1);
$table-th-color: rgba(32, 36, 43, 1);
$table-header-grey-background: #fafafa;

$statistics-icon-background-color: rgba(232, 237, 237, 1);
$statistics-icon-color: $brand-color;
$icon-default-color: rgba(153, 153, 153, 1);

$buy-extra-requests-border-color: #d7e8e1;

$feature-request-background-color: #f7f8fa;
$feature-request-border-color: #e4e7eb;
$feature-request-status-color: #787f8c;

$team-available-requests-background-color: #47746f;

$online-indicator-color: rgba(60, 179, 113, 1);

$switch-toggle-background-color: rgba(226, 226, 226, 1);

$no-crm-settings-block-background-color: #eeeeee7d;

$release-notes-fixes-background-color: #ff7f7f;
$release-notes-features-background-color: #13544e;
$new-release-indicator-background-color: #ff7f7f;

$settings_caption_color: #889ba0;

$error-color: rgba(255, 51, 51, 1);
$pale-error-color: rgba(255, 51, 51, 0.8);
$success-color: rgba(50, 205, 50, 1);
$success-color_50_percent: rgba(50, 205, 50, 0.5);

$box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;

$notification-success-linear-gradient: linear-gradient(35deg, #bed8c1, #a8d7d3);
$notification-error-linear-gradient: linear-gradient(43deg, #f8dbcf, #e6d0c6);
$notification-technical-linear-gradient: linear-gradient(37deg, #fcc, #f8d8e4);

$buy-requests-counter-background-color: rgba(236, 248, 242, 1);
$buy-requests-counter-border-color: #13544e1a;
$tabs-button-background-color: #f3f3f3;

$font-weight-bold: bold;

$data-table-border-color: rgba(5, 5, 5, 0.06);
$extension-updated-color: #d46b08;
