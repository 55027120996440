@import './src/styles';

.data-table {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 21px;
  color: $primary-text-color;
  position: relative;
  width: 100%;
  
  .ant-tag {
    margin: 0;
    font-size: 12px;
  }
  
  &__overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, .5);
    z-index: 10;
  }
  
  &__table {
    display: table;
    border-collapse: collapse;
    width: 100%;
  }
  
  tr.totals,
  thead {
    background: $table-header-grey-background;
    width: 100%;
    
    th, td {
      font-weight: $font-weight-bold;
      padding: 12px;
    }
  }
  
  tbody {
    background-color: #fff;
  }
  
  tr {
    min-height: 38px;
    width: 100%;
  }
  
  td {
    text-align: center;
    padding: 12px;
    
    span.value {
      display: flex;
      align-items: center;
      justify-content: center;
      
      &::before {
        content: attr(data-th)":";
        font-weight: $font-weight-bold;
        margin-right: 8px;
        line-height: 28px;
        display: inline-block;
        word-wrap: break-word;
        word-break: normal;
      }
  
      .button-wrap {
        display: inline-block;
        width: unset;
      }
    }
  }
  
  tbody {
    tr {
      cursor: pointer;
      
      &:nth-child(odd) {
        border-top: 1px solid $data-table-border-color;
        border-bottom: 1px solid $data-table-border-color;
      }
      
      &:last-child {
        border-bottom: 1px solid $data-table-border-color;
      }
  
      &:hover {
        transition: all .1s ease;
        background: #fafafa;
      }
    }
  }
  
  .expand-button {
    @include table-button;
    margin-left: 0;
    margin-right: 16px;
  }
  
  table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 8px;
  }
  
  table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 8px;
  }
  
  .table-th-column-name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  
  .sorting-controls {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 2px;
    margin-left: 6px;
    
    span {
      display: flex !important;
      height: 10px;
      width: 10px;
      cursor: pointer;
      
      &.active,
      &:hover {
        color: #389e0d;
      }
    }
  }
  
  .totals {
    td:nth-child(1) {
      span.value {
        justify-content: flex-start;
      }
    }
  }
  
  @media (min-width: 768px) {
    overflow-x: auto;
    
    td {
      span.value {
        &:before {
          display: none;
        }
      }
    }
  }
  
  @media (max-width: 767px) {
    border-start-start-radius: 8px;
    border-start-end-radius: 8px;
    background: transparent;
    box-shadow: $box-shadow;
    
    thead,
    .avatar { display: none; }
    
    tbody {
      border-start-start-radius: 8px;
      border-start-end-radius: 8px;
      background: transparent;
    }
  
    &__table {
      th, td {
        display: table-cell;
        padding: 12px;
        position: relative;
      }
    }
    
    .user-name {
      margin-left: 0 !important;
      line-height: 1.6;
    }
    
    tr {
      height: 38px;
      display: block;
      overflow: hidden;
      position: relative;
      
      &:nth-child(1) {
        border-top: none !important;
      }
      
      &.totals,
      &.no-records {
        min-height: 40px;
        height: unset;
      }
      
      &.totals {
        td {
          padding: 6px 16px;
          
          &:first-child {
            background-color: $brand-color;
            color: $secondary-background-color;
          }
        }
      }
      
      &.extended {
        height: auto;
      }
      
      &.extended,
      &.collapsed {
        div > .anticon {
          position: absolute;
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $secondary-background-color;
          font-weight: $font-weight-bold;
          border-radius: 50%;
          background-color: $brand-color;
          top: 7px;
          right: 8px;
          z-index: 4;
  
          svg {
            height: 12px;
            width: 12px;
          }
        }
      }
    }
    
    td {
      display: block;
      text-align: left;
      cursor: pointer;
      padding: 6px 16px;
      
      span.value {
        display: block;
        text-align: left !important;
        line-height: 1.2;
        font-size: 14px;
        
        .mobile-actions {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          text-decoration: underline;
          flex-wrap: wrap;
          cursor: pointer;
          
          label {
            margin-right: 8px;
          }
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    td span.value:before {
      min-width: unset;
    }
  }
  
  @media (max-width: 380px) {
    .ant-progress {
      display: none;
    }
    
    .user-name {
      align-items: center;
      justify-content: flex-end;
    }
  }
}