@import './src/styles';

.extension-info {
  $base: &;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 380px;
  
  &__content {
    position: relative;
    
    img {
      position: relative;
      top: 4px;
      max-width: 100%;
    }
  }
  
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $secondary-background-color;
    border-radius: 8px;
    padding: 48px;
    width: 100%;
  
    #{$base}__motto {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: .5px;
      margin: 0 0 32px;
      text-align: center;
      color: $primary-text-color;
      max-width: 80%;
    }
  
    #{$base}__description {
      font-size: 14px;
      line-height: 21px;
      color: $primary-text-color;
      text-align: center;
      margin: 0;
      max-width: 85.5%;
    }
  }
  
  @media (max-width: 480px) {
    width: 100%;
    
    &__container {
      border-radius: 0;
      padding: 0;
    }
  
    #{$base}__motto,
    #{$base}__description {
      max-width: 100%;
    }
  }
}