@import '../../styles/index';

.empty-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-color: rgba(19, 84, 78, .6);
    margin-bottom: 24px;
    
    svg {
      font-size: 75px;
      line-height: 90px;
      fill: rgb(232, 237, 237);
    }
  }
  
  span {
    font-size: 16px;
    line-height: 24px;
    color: #333;
    text-align: center;
  }
}