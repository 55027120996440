@import './src/styles';

.no-records-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  line-height: 21px;
  background: $no-crm-settings-block-background-color;
  color: $primary-text-color;
  width: 100%;
}