@import "./src/styles";

.column {
  display: flex;
  flex-direction: column;
}

.trial-plan-label {
  position: absolute;
  transform: rotate(-45deg);
  left: -50px;
  height: 28px;
  width: 180px;
  background: #13544e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.plan {
  @extend .column;
  align-items: center;
  justify-content: space-between;
  background: $primary-background-color;
  border-radius: 6px;
  padding: 24px;
  box-shadow: $box-shadow;
  min-height: 392px;
  width: 300px;
  position: relative;
  overflow: hidden;

  .plan-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 24px;
  }

  .plan-price {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 14px;
    color: $primary-text-color;

    .price {
      position: relative;
      margin-bottom: -8px;
      margin-right: 4px;
      font-size: 32px;
      color: $dark-charcoal-color;

      svg {
        font-size: 14px;
        position: absolute;
        top: 14px;
        left: -14px;
      }
    }
  }

  .plan-features {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    padding-left: 16px;
    width: 100%;

    .plan-feature {
      font-size: 14px;
      line-height: 21px;
      word-break: break-word;
    }
  }

  .plan-label {
    display: flex;
    align-self: flex-end;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    background: $brand-color;
    border: none;
    color: $secondary-background-color;
  }

  .plan-button {
    width: 100%;
  }

  s {
    font-size: 32px;
    color: $dark-charcoal-color;
    margin-right: 2px;
    position: relative;
    text-decoration: none;

    &::before {
      top: 50%; /*tweak this to adjust the vertical position if it's off a bit due to your font family */
      background: red; /*this is the color of the line*/
      opacity: 0.7;
      content: "";
      width: 110%;
      position: absolute;
      height: 0.1em;
      border-radius: 0.1em;
      left: -5%;
      white-space: nowrap;
      display: block;
      transform: rotate(-15deg);
    }
  }
}
