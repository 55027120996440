@import './src/styles';

.feature-request {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 32px;
  max-height: 85vh;
  
  &__main-title {
    font-size: 21px;
    line-height: 28px;
    font-weight: 500;
    margin: 0 0 16px;
  }
  
  &__description {
    font-size: 14px;
    line-height: 21px;
  }
  
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    position: sticky;
    margin-right: 32px;
    background: $feature-request-background-color;
    border: 1px solid $feature-request-border-color;
    border-radius: 8px;
    padding: 16px;
    height: 409px;
    width: 300px;
  }
  
  &__form {
    height: auto;
    margin-top: 16px;
    
    .field-input {
      margin-bottom: 16px;
    }
  }
  
  &__button-wrap {
    display: flex;
    align-self: center;
    justify-content : flex-end;
    margin-top: 16px;
  }
  
  &__features-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
  }
  
  &__feature {
    display: flex;
    align-items: flex-start;
    background-color: $feature-request-background-color;
    box-shadow: rgba(0, 0, 0, 0.05) 0 3px 12px;
    border: 1px solid $feature-request-border-color;
    border-radius: 4px;
    cursor: pointer;
    flex-flow: row nowrap;
    padding: 16px;
    user-select: none;
    transition: all .3s ease;
    
    &:hover {
      background: $primary-background-color;
    }
    
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  
  &__vote {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    color: rgb(120, 127, 140);
    
    svg {
      height: 20px;
      width: 20px;
      margin-bottom: 8px;
      transition: all .3s ease;
      
      &:hover {
        color: $brand-color;
      }
    }
  }
  
  &__feature-content {
    display: flex;
    flex-direction: column;
  }
  
  .row {
    margin-bottom: 8px;
  }
  
  &__feature-title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 700;
  }
  
  &__feature-description {
    font-size: 14px;
    line-height: 21px;
    padding: 0;
    margin: 0;
  }
  
  &__feature-status {
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin-left: 8px;
    height: 16px;
    color: $feature-request-status-color;
  }
  
  &__feature-status-indicator {
    border-radius: 100%;
    height: 8px;
    margin-right: 8px;
    min-width: 8px;
    width: 8px;
    background: green;
  }
  
  &__search-and-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    
    .field-input {
      min-width: 240px;
    }
  }
}