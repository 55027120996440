@import './src/styles';

$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
}

.plan-skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 392px;
  min-width: 300px;
  padding: 24px;
  box-shadow: $box-shadow;
  
  &__title {
    display: flex;
    align-items: center;
    justify-self: center;
    height: 24px;
    width: 120px;
    margin: 14px 0;
    @include background-gradient;
    animation: shine-avatar $animation-duration infinite linear;
  }
  
  &__price {
    height: 41.5px;
    @include background-gradient;
    animation: shine-avatar $animation-duration infinite linear;
    width: 100%;
  }
  
  &__points {
    height: 168px;
    margin-top: 32px;
    margin-bottom: 16px;
    width: 100%;
    @include background-gradient;
    animation: shine-avatar $animation-duration infinite linear;
  }
  
  &__button {
    height: 32px;
    width: 100%;
    @include background-gradient;
    animation: shine-avatar $animation-duration infinite linear;
  }
  
  margin-bottom: 24px;
  margin-left: 32px;
  
  &:first-child {
    margin-left: 0;
  }
}