@import './src/styles';

.field-select {
  display: flex;
  flex-direction: column;
  
  > .form-select {
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 140px;
    
    &:hover,
    &:focus {
      outline: none;
      border-color: $brand-color;
    }
  }
  
  &__label {
    font-size: 14px;
    line-height: 21px;
    font-weight: bold;
    color: $primary-text-color;
    margin-bottom: 4px;
  }
  
  &__error {
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $error-color;
    font-size: 12px;
    margin: 6px 0 0;
  }
}