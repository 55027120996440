@import './src/styles';

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $primary-text-color;
  
  .ant-spin {
    .ant-spin-dot-item {
      background: $online-indicator-color;
    }
  
    .ant-spin-dot {
      font-size: 16px;
    }
  }
}