@import './src/styles';

.payment-check-status-popup {
  .cancel-popup {
    &__body {
      > .cancel-popup__main-text {
        &:nth-child(1) {
          font-weight: bold;
          margin-bottom: 8px;
        }
      }
    }
  }
  
  .svg-icon {
    display: flex;
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-left: 6px;
    
    svg {
      fill: $brand-color;
      height: 100%;
    }
  }
}