@import './src/styles';

.avatar {
  &.regular {
    height: 40px;
    width: 40px;
  }
  
  &.small {
    height: 18px;
    width: 18px;
  }
  
  img,
  svg {
    border-radius: 50%;
    fill: $icon-default-color;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}