@import "./src/styles";

.plan-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
  width: 100%;

  > div {
    width: 32%;
  }

  .current-plan-label {
    background: #13544e;
    border: none;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #fff;
    display: flex;
    margin: 0;
    font-size: 12px;
    padding: 0px 8px;
  }

  .discount {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    word-wrap: break-word;
    word-break: break-word;

    .caption {
      font-size: 10px;
      line-height: 12px;
    }
  }

  @media (max-width: 580px) {
    > div {
      width: 48%;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 400px) {
    > div {
      width: 100%;
    }
  }
}
