.select-payment-method {
  margin-top: 24px;

  &__note {
    margin-bottom: 8px;
  }

  &__methods {
    display: flex;
    flex-direction: column;

    .ant-radio-wrapper {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }

  &__buttons-panel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;

    button {
      height: 32px;
      margin-left: 16px;
    }
  }
}