@import './src/styles';

.gpt {
  display: flex;
  background: $secondary-background-color;
  color: $primary-text-color;
  font-size: 14px;
  line-height: 21px;
  padding: 4px 0;
  
  &__title {
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 8px;
    padding: 0;
  }
  
  &__description {
    margin: 0 0 16px;
    padding: 0;
    max-width: 320px;
  }
  
  a {
    color: $brand-color;
    font-weight: bold;
  }
}