@import './src/styles';

.manage-seats {
  .caption {
    margin: 0;
    font-size: 12px;
    color: $primary-text-color;
  }

  .form-title {
    font-size: 14px;
    font-weight: bold;
    margin: 16px 0 12px;
  }

  .counter {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid $success-color;
    background: $success-color_50_percent;
    width: 100%;

    .counter-button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      cursor: pointer;
      height: 100%;
      width: 100%;

      &:nth-child(2) {
        border-left: 1px solid $success-color;
        border-right: 1px solid $success-color;
        cursor: default;
      }

      svg {
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
}