@import './src/styles';

.buy-extra-requests-modal {
  display: flex;
  align-items: center;
  
  &__counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    margin: 12px 0;
    border-radius: 4px;
    background: $buy-requests-counter-background-color;
    border: 2px solid $buy-requests-counter-border-color;
    color: $brand-color;
    user-select: none;
  }
  
  &__section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 70px;
    flex: 1;
    cursor: pointer;
    
    &:nth-child(1),
    &:nth-child(3) {
      font-size: 24px;
      font-weight: normal;
    }
    
    &:nth-child(2) {
      font-size: 40px;
      border-left: 2px solid $buy-extra-requests-border-color;
      border-right: 2px solid $buy-extra-requests-border-color;
    }
  }
  
  &__order-notice {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    user-select: none;
  }
}