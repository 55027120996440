@import "./src/styles";

.job-searcher {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .ant-tabs-tabpane-active {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 0;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
    padding-top: 0;
    z-index: 1;
  }

  .ant-tabs-tab {
    font-size: 16px;
    font-weight: 600;
    width: 120px;
    display: flex;
    justify-content: center;

    &:hover {
      .ant-tabs-tab-btn {
        color: rgb(19, 84, 78);
      }
    }

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: rgb(19, 84, 78);
      }
    }
  }

  .ant-tabs-ink-bar-animated {
    background: rgb(19, 84, 78);
  }

  .ant-tabs-nav-list {
    width: 100%;
    border-bottom: 1px solid rgb(243, 243, 243);
  }

  .ant-tabs-nav-wrap {
    background: #fff;
    padding: 6px 32px 0 32px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    & > button {
      margin: 0 !important;
      display: flex;
      gap: 6px;
    }
  }

  &__link {
    cursor: pointer;
    font-weight: 600;
    color: rgb(19, 84, 78);
  }

  &__filters {
    display: flex;
    align-items: center;
    gap: 8px;

    & > label {
      font-weight: 600;
    }
  }

  &__not-paid {
    padding: 30px 0;

    h3 {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 32px;
      text-align: center;
      line-height: 32px;
    }

    p {
      text-align: center;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 22px;
    }

    .ant-btn {
      margin: 0 auto;
    }
  }

  &__preview {
    display: flex;
    align-items: center;
  }

  &__preview-content {
    display: flex;
    flex-direction: column;
    width: calc(40% - 40px);
    padding: 10px 20px;
    justify-content: center;
    min-height: 100%;
    height: max-content;

    button {
      min-width: 140px !important;
    }

    & > span {
      text-align: center;
      color: #515151;
      padding-top: 14px;
    }
  }

  &__preview-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
  }
}

.loading-template {
  padding-top: 120px;
  padding-bottom: 120px;
}
