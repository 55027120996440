@import './src/styles';

.general-statistics {
  position: relative;
  z-index: auto;
  
  &__metrics {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    
    .parameter {
      display: flex;
      width: 24%;
      
      &:not(:nth-child(-n+4)) {
        margin-top: 24px;
      }
    }
  }
  
  @media (max-width: 767px) {
    .parameter {
      margin-top: 24px;
      width: 33%;
    }
  }
  
  @media (max-width: 640px) {
    .parameter {
      width: 50%;
    }
  }
  
  @media (max-width: 480px) {
    .parameter {
      width: 100%;
      
      .icon {
        min-height: 48px;
        min-width: 48px;
        font-size: 24px;
      }
      
      .details {
        height: 48px;
        margin-left: 12px;
      }
      
      .value {
        font-size: 20px;
        line-height: 27px;
      }
      
      .title {
        font-size: 14px;
      }
    }
  }
  
  @media (max-width: 480px) {
    &__metrics {
      .parameter:first-child {
        margin-top: 0;
      }
    }
  }
}