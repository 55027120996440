@import "./src/styles";

.data-table-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 0;
  line-height: 24px;
  width: 100%;

  select {
    border: none;
    border-bottom: 1px dotted $data-table-border-color;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
  }

  button {
    @include table-button;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__page-selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__per-page-selector {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 auto;
  }

  @media (max-width: 767px) {
    padding: 16px;
  }

  @media (max-width: 375px) {
    &__per-page-selector {
      margin-bottom: 0;
    }

    &__page-selector {
      width: 100%;
    }

    button {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1;
    font-size: 12px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgb(19, 84, 78);
      color: #fff;
    }

    .arrow {
      position: relative;
      &::before {
        position: relative;
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-right: 0.12em solid rgb(19, 84, 78);
        border-top: 0.12em solid rgb(19, 84, 78);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
        left: -1px;
        top: -4px;
      }

      &.right {
        transform: rotate(45deg);
        left: -2px;
        top: 1px;
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
