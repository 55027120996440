@import "./src/styles";

.tool {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 16px;
  background: $secondary-background-color;
  box-shadow: $box-shadow;
  border-radius: 4px;
  margin-bottom: 32px;
  position: relative;
  width: 340px;
  z-index: 2;

  &__image-wrap {
    height: 60px;
    width: 60px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    width: 100%;
  }

  &__name {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    margin: 0 0 8px;
    color: $primary-text-color;

    svg {
      position: relative;
      height: 14px;
      width: 14px;
      top: 2px;
      margin-left: 4px;
      fill: $brand-color;
      cursor: pointer;
    }
  }

  .form-input,
  &__button {
    box-shadow: none;
    height: 32px;
    width: 100%;
  }

  &.pipedrive {
    img {
      filter: opacity(50%);
    }
  }

  &__delete-button {
    &.button.ant-btn {
      margin-left: 12px;
      min-width: 36px;
      height: 28px;
      width: 30px;
      padding: 0;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
  }
}
