@import './src/styles';

.nothing-found {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 100%;
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  padding: 24px;
  background: $secondary-background-color;
  
  &__content {
    align-items: center;
    justify-content: center;
    
    svg {
      height: 300px;
      width: 100%;
      max-width: 500px;
    }
  }
}