@import "./src/styles";

.job-bot-status {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__button {
    display: flex;
    margin: 16px 0 10px auto;
    min-width: 120px;
  }

  &__info {
    border: 1px solid rgb(243, 243, 243);
    border-radius: 4px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  &__info-tooltip {
    display: flex;
    align-items: center;
    margin: 16px 0 10px auto;

    & > svg {
      width: 18px;
      height: 18px;
      color: #f6766f;
      cursor: pointer;
    }
  }

  &__info-item {
    display: flex;
    align-items: center;
    gap: 10px;

    .ant-tag {
      text-transform: capitalize;
    }

    & > span {
      font-weight: 600;
    }
  }
}

.soon-label {
  position: absolute;
  right: 0;
  background: rgb(19, 84, 78);
  color: #fff;
  top: 0;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 12px;
}

.profile-stats-panel {
  position: relative;
}
