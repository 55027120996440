@import './src/styles';

.requested-feature {
  background-color: $primary-background-color;
  box-shadow: rgba(0, 0, 0, .05);
  border: 1px solid #e4e7eb;
  border-radius: 4px;
  flex-direction: column;
  cursor: pointer;
  padding: 16px;
  
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  
  &__title {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: $primary-text-color;
    font-weight: 500;
  }
  
  &__control-icons {
    display: flex;
    align-items: center;
    margin-top: 16px;
    width: 100%;
    
    span {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #787f8c;
      margin-right: 12px;
      
      svg {
        height: 12px;
        min-width: 12px;
        color: #787f8c;
        margin-right: 4px;
        transition: all .3s ease;
        width: 12px;
      }
      
      &:nth-child(1) {
        svg {
          position: relative;
          bottom: 1px;
          
          &:hover {
            color: green;
          }
        }
      }
      
      &:nth-child(2) {
        svg {
          position: relative;
          top: 1px;
          
          &:hover {
            color: red;
          }
        }
      }
    }
  }
}