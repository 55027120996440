@import './src/styles';

.release-notes {
  $base: &;
  display: flex;
  flex-direction: column;
  background: $primary-background-color;
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
  position: relative;
  margin: 24px 0;
  width: 100%;
  
  &__show-more-releases {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 24px;
    height: 52px;
    width: 100%;
    
    &:before {
      content: '';
      height: 50px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      background-color: rgba(243, 243, 243, .85);
    }
    
    button {
      cursor: pointer;
      border: none;
      background: transparent;
      font-size: 14px;
      line-height: 21px;
      font-weight: bold;
      color: $brand-color;
      z-index: 5;
      
      &:hover {
        color: seagreen;
      }
    }
  }
  
  &__intro {
    margin-bottom: 8px;
  }

  &__main-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .column {
    padding: 16px;
    font-size: 14px;
    line-height: 21px;
  
    h3, strong {
      font-size: 14px;
      color: $brand-color;
      margin: 0;
      padding: 0;
    }
    
    &--left {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-width: 220px;
      margin-right: 32px;
      padding: 0 16px;
    }
    
    &--right {
      background: $secondary-background-color;
      border-radius: 4px;
      box-shadow: $box-shadow;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 18px;
      padding: 20px 35px;
      flex: 1;
  
      ul {
        padding: 0 15px;
        margin: 0;
      }
  
      h3 {
        margin-bottom: 8px;
      }
    }
    
    li {
      font-size: 14px;
      line-height: 21px;
      list-style-type: circle;
      margin-bottom: 8px;
    }
  }
  
  &__title,
  &__release-major-title {
    margin: 0 0 4px;
    padding: 0;
    color: $brand-color;
    font-weight: normal;
  }
  
  &__release-major-title {
    font-size: 18px;
    margin: 0 0 12px;
  }
  
  .releases-accordion {
    display: flex;
    flex-direction: column;
    height: 75vh;
    overflow-y: auto;
    position: relative;
    
    .field-select {
      margin-bottom: 16px;
      outline: none;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      .ant-select,
      .ant-select-selector {
        height: 40px;
        border: none;
        border-radius: 0;
        background: #fff;
        display: flex;
        align-items: center;
        width: 100%;
      }
    }
    
    &.show-more {
      height: 100%;
      
      .release-notes__releases-list {
        padding-bottom: 42px;
      }
    }
  }
  
  &__release-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $brand-color;
    font-weight: normal;
    margin-bottom: 8px;
    overflow: hidden;
    min-height: 24px;
    transition: 0.3s;
  }
  
  &__releases-list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    transition: height 0.35s ease;
    
    &.expanded {
      height: auto;
    }
  }
  
  &__release {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 24px;
    width: 100%;
    padding: 0;
    color: $brand-color;
    cursor: pointer;
    
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    
    &.active,
    &:hover {
      transition: all .3s ease;
      
      > .release-notes__release-version {
        color: seagreen;
      }
    }
  }
  
  &__release-version {
    font-size: 14px;
    font-weight: bold;
    color: $brand-color;
  }
  
  &__publish-time {
    font-weight: normal;
    cursor: text;
  }
  
  &__indicator {
    background: $release-notes-features-background-color;
    border-radius: 12px;
    display: flex;
    height: 6px;
    min-width: 90px;
    position: relative;
  }
  
  &__fixes-share {
    background: $release-notes-fixes-background-color;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
  
  &__indicator-fix-features {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    bottom: 14px;
    height: 12px;
    width: 100%;
  }
  
  &__indicator-value {
    display: flex;
    justify-content: flex-end;
    position: relative;
    font-size: 12px;
    
    &.fixes {
      color: $release-notes-fixes-background-color;
    }
    
    &.features {
      color: $release-notes-features-background-color;
      right: 1px;
    }
  }
  
  &__author {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  &__time-ago-since-published {
    color: $brand-color;
  }
  
  &__release-details.column {
    display: flex;
    padding: 0;
    margin-top: 20px;
    font-size: 14px;
    color: $primary-text-color;
    width: 100%;
    
    img {
      display: block;
      max-width: 550px;
      width: 100%;
      margin: 14px 0;
    }
  }
  
  &__release-heading {
    font-size: 15px;
    font-weight: bold;
    color: $brand-color;
    margin: 0 0 16px;
  }
  
  @media (max-width: 767px) {
    #{$base}__main-content {
      flex-direction: column;
      
      .column {
        &--left {
          margin: 0;
          padding: 0;
          width: 100%;
        }
        
        &--right {
          width: 100%;
        }
      }
    }
  
    .releases-accordion {
      height: unset;
      margin-bottom: 24px;
      overflow-y: visible;
      width: 100%;
    }
  }
  
  @media (max-width: 420px) {
    #{$base}__main-content {
      .column {
        &--right {
          padding: 20px;
        }
      }
    }
  }
}
