@import './src/styles';

.parameter {
  display: flex;
  align-items: flex-start;
  background: $secondary-background-color;
  cursor: pointer;
  min-width: 120px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    min-width: 40px;
    font-size: 16px;
    border-radius: 50%;
    background: $statistics-icon-background-color;
  
    svg {
      color: $brand-color;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  .value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    color: $dark-charcoal-color;
    width: 100%;
  }

  .title {
    font-size: 14px;
    font-weight: normal;
    word-break: break-word;
    color: $primary-text-color;
    width: 100%;
    
    svg {
      margin-left: 4px;
      position: relative;
      top: 2px;
      color: $brand-color;
    }
  }
}