@import "./src/styles";

.tools-and-apps {
  margin-top: 24px;

  &__section-title {
    font-size: 18px;
    font-weight: 600;
    color: $primary-text-color;
    margin-bottom: 24px;
  }

  &__ai-tools {
    display: flex;
    gap: 32px;
    padding-bottom: 24px;
  }

  .information-block {
    padding: 32px 16px;
    width: 340px;
  }
}

.pipedrive-form {
  &__available {
    color: #389e0d;
    background: #f6ffed;
    border: 1px solid #b7eb8f;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 2px 8px;
    font-size: 14px;
    height: 28px;
  }
}

.ai-key-personal-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
