@import './src/styles';

.ribbon {
  height: 140px;
  overflow: hidden;
  position: absolute;
  width: 125px;
  z-index: 2;
  
  span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: $new-release-indicator-background-color;
    color: $secondary-text-color;
    font: 700 14px / 0 Roboto, sans-serif;
    letter-spacing: 2px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
  }
  
  &.ribbon-top-right {
    top: 0;
    right: 0;
    
    span {
      left: -25px;
      top: 30px;
      transform: rotate(45deg);
    }
  }
}