@import "./src/styles";

.job-tool {
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  display: flex;
  border-radius: 50%;
  cursor: pointer;

  &.success-tool {
    background-color: #389e0d;
  }

  &.error-tool {
    background-color: #ffa39e;
  }
}

.jobs {
  .data-table {
    .collapsed {
      cursor: inherit;
    }

    tbody {
      td {
        padding: 6px 12px;

        .ant-tag {
          cursor: inherit;
        }
      }
    }
  }

  &__actions {
    display: flex;
    gap: 10px;

    button {
      width: auto;
      min-width: auto !important;
    }
  }

  .avatar-wrap {
    display: flex;
    align-items: center;
  }

  .user-name {
    margin-left: 8px;
    text-align: left;
    flex: 1 1 0;
  }

  .clickable-value {
    display: inline-block;
    color: $brand-color;
    font-weight: 500;
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 5px;
      min-width: 16px;
    }

    &.job-link {
      font-weight: 700;
      text-decoration: none;
      cursor: pointer;

      & > span {
        max-width: 360px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  @media (max-width: 767px) {
    .avatar-wrap {
      display: inline;
      overflow: hidden;
    }
  }
}
