@import "./src/styles";

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  background: $secondary-background-color;
  overflow-y: auto;
  padding: 16px;
  width: 240px;
  min-width: 200px;
  min-height: 100vh;

  .brand-logo {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    padding: 0 8px;
  }

  .title {
    font-size: 14px;
    line-height: 18px;
    color: $primary-text-color;
    margin: 0 0 12px;
    padding: 0 8px;
    text-transform: uppercase;

    &:not(:nth-child(1)) {
      margin-top: 16px;
    }
  }

  .icon {
    font-size: 18px;
    margin-right: 12px;
    max-width: 18px;
  }

  a {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    color: $links-from-sidebar-color;
    height: 40px;
    padding: 10px 8px;
    transition: 0.3s all ease;
    width: 100%;

    &.active,
    &:hover {
      background: $links-from-sidebar-active-color;
    }
  }

  .new-release-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: $new-release-indicator-background-color;
    margin-left: 4px;
  }

  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-100%);
    transition: 0.4s ease all;
    z-index: 10;

    &.active {
      display: flex;
      transform: translateX(0%);
      z-index: 20;
    }
  }
}
