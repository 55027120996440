@import './src/styles';

.billing-details {
  &__company-name {
    font-size: 14px;
    margin: 0 0 8px;
    display: flex;
    align-items: center;
  }
  
  &__details {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 21px;
  }

  &__link,
  .link {
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    color: $brand-color;
    cursor: pointer;
    
    &:hover {
      color: $btn-hover-background-color;
      text-decoration: underline;
    }
  }

  &__divider {
    height: 1px;
    background: $primary-text-color;
    width: 100%;
    margin: 16px 0;
  }
}