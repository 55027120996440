.billing {
  margin-top: 24px;
}

.info-block-title {
  font-size: 16px;
  margin: 0 0 10px 0;
  font-weight: 700;
}

.m-b-24 {
  margin-bottom: 24px;
}
